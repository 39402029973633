import React, { Component } from 'react';
import dynamic from 'next/dynamic'
import { connect } from 'react-redux';
// import Link from '../../utils/ActiveLink';
const SearchInput = dynamic(() => import('./SearchInput' ))
import { MY_LOCATION_PLACE_NAME, MY_LOCATION_PLACE } from '../../helpers/constTypes'
import { updateModalStatus } from '../../store/actions/cartActions';
import cookie from 'js-cookie'
import Link from 'next/link';
import { BrowserView, MobileView } from 'react-device-detect';
const MobileSearch = dynamic(() => import('./MobileSearch'))
const SiteWideDealPopup = dynamic(() => import('./SiteWideDealPopup'))
import LogoImage from '../../images/logo-small.png'
import Image from 'next/image';
import isEmpty from '../../utils/isEmpty';

class Navbar extends Component {
    // Navbar

    _isMounted = false;
    state = {
        display: false,
        collapsed: true,
        whiteLogo:false,
        search:false,
        searchOpened: false,
        SessionModal: true,
        sticky: true
    };

    getLocationDesc = (type) => {

        let newStr = cookie.get(MY_LOCATION_PLACE_NAME)
            .replace('"', '')
            .replace('"', '')
            .replace(', United States', '')
        newStr = newStr.substr(newStr.lastIndexOf(",")+2, newStr.length-newStr.lastIndexOf(","))

        switch (type) {
            case 'state':
                return newStr.substr(0, newStr.indexOf(' '))
                break;

            case 'zip':
                return newStr.substr(newStr.indexOf(' ')+1, newStr.length-newStr.indexOf(' ')+1)
                break;

            default:
                break;
        }
    }



    openModal = (modal)=>{
        this.props.updateModalStatus({[modal]:true});
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    setSearchActive = (value) => {

        this.setState({search:value})
    }

    setSearchOpened = (value) => {

        // document.body.style.overflow = value ? 'hidden' : 'unset'
        // document.body.style.touchAction = value ? 'none' : 'auto'
        this.setState({searchOpened:value})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Search Modal
    toggleModalSearch = () => {
        this.setState({
            SearchModal: !this.state.SearchModal
        });
    }


    // Location Modal
    toggleModalLocation = () => {
        this.setState({
            LocationModal: !this.state.LocationModal
        });
    }



    // Shopping Cart Modal
    toggleModalCart = () => {
        this.setState({
            ShoppingCartModal: !this.state.ShoppingCartModal
        });
    }

    // Sidebar Modal

    toggleModalSidebar = () => {
        this.setState({
            SidebarModal: !this.state.SidebarModal
        });
    }


    toggleSearchActive = () => {
        this.setState({
            search: !this.state.search
        });
    }


    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    locationDescription = (str) => {
        let a = str.replace('"','').replace('"','').replace(', United States', '')
        let aa = `${a.substr(0, a.indexOf(','))}, ${this.statesF[cookie.get('REGION')]}`
        return aa


    }

    totalQuantity = () => {
        var total = 0
        this.props.products.map(product => {
            total += parseInt(product.quantity, 10);
        })
        return total
    }


    statesF = {
        'Alabama':'AL',
    'Alaska':'AK',
    'Arizona':'AZ',
    'Arkansas':'AR',
    'California':'CA',
    'Colorado':'CO',
    'Connecticut':'CT',
    'Delaware':'DE',
    'Florida':'FL',
    'Georgia':'GA',
    'Hawaii':'HI',
    'Idaho':'ID',
    'Illinois':'IL',
    'Indiana':'IN',
    'Iowa':'IA',
    'Kansas':'KS',
    'Kentucky':'KY',
    'Louisiana':'LA',
    'Maine':'ME',
    'Maryland':'MD',
    'Massachusetts':'MA',
    'Michigan':'MI',
    'Minnesota':'MN',
    'Mississippi':'MS',
    'Missouri':'MO',
    'Montana':'MT',
    'Nebraska':'NE',
    'Nevada':'NV',
    'New Hampshire':'NH',
    'New Jersey':'NJ',
    'New Mexico':'NM',
    'New York':'NY',
    'North Carolina':'NC',
    'North Dakota':'ND',
    'Ohio':'OH',
    'Oklahoma':'OK',
    'Oregon':'OR',
    'Pennsylvania':'PA',
    'Rhode Island':'RI',
    'South Carolina':'SC',
    'South Dakota':'SD',
    'Tennessee':'TN',
    'Texas':'TX',
    'Utah':'UT',
    'Vermont':'VT',
    'Virginia':'VA',
    'Washington':'WA',
    'West Virginia':'WV',
    'Wisconsin':'WI',
    'Wyoming':'WY',
    'District of Columbia':'DC'
    }

    render() {
        const { products, user } = this.props;
        const { collapsed } = this.state;
        const classNameOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classNameTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        return (
            <React.Fragment>
                <SiteWideDealPopup />
                <div className='hide-mobile' style={{height:'89px', backgroundColor:'white'}}></div>
                <div className='hide-desktop' style={{height:'50px', backgroundColor:'white'}}></div>
                <header id="navbar" className={`${this.state.searchOpened ? 'search-open' : 'no-search-open'} fixed navbar-area is-sticky`}>
                    <div className="main-nav ">
                        <nav className={`navbar mobile-v navbar-expand-md navbar-light flex-row container-limiter pb-0`}>
                            <button
                                onClick={
                                    ()=>{
                                        this.openModal('menu')
                                    }
                                }
                                className={classNameTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <Link prefetch={false} href="/" style={{ marginRight: '.5rem', marginRight:'-1rem'}} className={this.state.searchOpened ? ' aside-filter d-flex align-items-center' : 'd-flex align-items-center aside-filter'}>

                                    <>
                                    <Image
                                        alt='Weedzly Logo.'
                                        width={32}
                                        height={32}
                                        // layout='raw'
                                        loader={({src})=> src}
                                        src={LogoImage}
                                    ></Image>
                                    <div className='d-flex flex-column brand-text-logo ml-1'>
                                        <span className='brand-name alone hide-380'>weedzly</span>
                                    </div> </>

                            </Link>
                            <div className='w-100'>
                                <BrowserView>
                                    <SearchInput
                                        // active={this.state.search}
                                        setSearchOpened={(value)=>this.setSearchOpened(value)}
                                        searchOpened={this.state.searchOpened}
                                        className={'mr-auto'}
                                        setSearchOpened={this.setSearchOpened}
                                    />
                                </BrowserView>
                            </div>

                            <div className='hide-desktop d-flex mobile-v' style={{marginLeft: 'auto'}}>
                                <div>
                                    <button aria-label='Change location' className={`btn btn btn-outline-secondary btn-pill btn-sm d-flex align-items-center justify-content-start location-big-button`} style={{fontSize:'12px'}} onClick={()=>this.openModal('location')}>
                                        <i className='bx bx-map mr-1'></i>{cookie.get(MY_LOCATION_PLACE_NAME) ? this.locationDescription(cookie.get(MY_LOCATION_PLACE_NAME)).substr(0, this.locationDescription(cookie.get(MY_LOCATION_PLACE_NAME)).indexOf(','))+(`, ${this.statesF[cookie.get('REGION')]}`) : 'Set location'}
                                    </button>
                                </div>

                                <div className='ml-2 d-flex align-items-center justify-content-center'>
                                    <button aria-label='Search on Weedzly' style={{height:'24px'}} type='button' onClick={()=>{this.setSearchActive(true); this.setSearchOpened(true)}} className={`btn cart-button pl-0 pr-0 pb-0 pl-0 pr-0 pt-0 text-dark ${this.state.searchOpened ? 'd-none' : ''}`}>
                                        <i className="search-btn bx bx-search" style={{fontSize:'22px'}}></i>
                                    </button>
                                </div>
                                <div className='ml-2 d-flex align-items-center justify-content-center'>
                                    <button aria-label='Open cart' title='Cart button' className='p-2 ' style={{height:'24px'}} onClick={()=>this.openModal('shoppingCart')} type='button' className={`pl-0 pr-0 pl-0 pr-0 btn cart-button pb-0 pt-0 text-dark`}>
                                        <i className="search-btn bx bx-cart-alt" style={{fontSize:'22px'}}></i>
                                        {
                                            this.props.products.length > 0 && <span className='badge badge-info badge-circle badge badge-info badge-circle badge-absolute'>
                                                {this.totalQuantity()}
                                            </span>
                                        }
                                    </button>
                                </div>

                                {
                                    isEmpty(user) && <div className='ml-2 d-flex align-items-center justify-content-center'>
                                        <button aria-label='Open profile' className='p-2 ' style={{height:'24px'}} type='button' onClick={()=>this.openModal('login')} className={`btn cart-button pl-0 pr-0 pb-0 pl-0 pr-0 pt-0 pt-0 text-dark `}>
                                            <i className="search-btn bx bx-user" style={{fontSize:'22px'}}></i>
                                        </button>
                                    </div>
                                }
                                    {
                                        (Object.keys(user).length > 0 && user.constructor === Object) ? (
                                            <>
                                                <div className='ml-2'>
                                                    <button aria-label='Open notifications' style={{height:'24px'}} type='button' onClick={()=>this.openModal('notification')} className={`btn pb-0 pl-0 pr-0 pt-0 text-dark`}>
                                                        <i title='Open notifications' className="search-btn bx bx-bell" style={{fontSize:'24px'}}></i>
                                                    </button>
                                                </div>
                                                <div className='ml-2'>
                                                    <button aria-label='Open profile' style={{height:'24px'}} type='button' onClick={()=>this.openModal('user')} className={`btn pb-0 pt-0 pl-0 pr-0 text-dark`}>
                                                        <i title='Open profile' className="search-btn bx bx-user" style={{fontSize:'24px'}}></i>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <button aria-label='Login in weedzly' title='Login in weedzly' className='btn btn-sm text-dark hide-mobile mr-2' onClick={()=>this.openModal('signup')}>Sign up</button>
                                                <button aria-label='Signup in weedzly' title='Signup in weedzly' className='btn btn-sm text-dark hide-mobile' onClick={()=>this.openModal('login')}>Login</button>
                                            </>
                                        )
                                    }
                                </div>

                            <a href='https://enterprise.weedzly.com/' target='_blank' className='btn text-purple hide-mobile'>Advertise With Weedzly</a>
                        </nav>
                        <nav className={`navbar navbar-expand-md navbar-light flex-row container-limiter pt-0 pb-0 pr-3 pl-3 `}>
                            <div className='aside-filter ' style={{marginRight:'-1rem'}}>
                            <div className='location-handler hide-mobile'>
                                <div className="no-padding btn nav-link location-button">
                                {
                                     !cookie.get(MY_LOCATION_PLACE_NAME) ? (
                                         <span style={{textDecoration:'underline'}} onClick={()=>this.openModal('location')}>
                                             <i className="search-btn bx bxs-map"></i>
                                             <span>Set location</span>
                                         </span>
                                     ) : (
                                         <>
                                             <i className="search-btn bx bxs-map active"></i>

                                             <span className='deliver-info' onClick={()=>this.openModal('location')} style={{textDecoration:'underline'}}>{this.locationDescription(cookie.get(MY_LOCATION_PLACE_NAME)).replace(', undefined', '')}

                                             </span>

                                         </>
                                     )
                                 }
                                </div>
                            </div>
                            </div>
                            <ul className={`navbar-nav hide-mobile`} style={{marginLeft:'-13px'}}>
                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/dispensaries'.length) === '/dispensaries' && !window.location.pathname.includes('/deals/') && !this.props.updatex ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false}  href="/[business]" as="/dispensaries">

                                            Dispensaries

                                    </Link>
                                </li>

                                {
                                    cookie.get('IS_DELIVERY_AVAILABLE') === 'true' && (
                                        <li className={`nav-item border-active ${window.location.pathname.substr(0, '/deliveries'.length) === '/deliveries' && !window.location.pathname.includes('/deals/') && !this.props.updatex ? 'current' : ''}`}>
                                            <Link className="nav-link" prefetch={false} href="/[business]" as="/deliveries">

                                                    Deliveries

                                            </Link>
                                        </li>
                                    )
                                }

                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/deals'.length) === '/deals' ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false} href={`${cookie.get('REGION') ? '/deals/[state]' : '/deals'}`} as={`${cookie.get('REGION') ? '/deals/'+cookie.get('REGION').toLowerCase() : '/deals'}`}>


                                            Deals

                                    </Link>
                                </li>
                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/map'.length) === '/map' ? 'current' : ''}`}>
                                    <Link prefetch={false}
                                        className="nav-link"
                                        href={'/map'}
                                        as={'/map'}
                                    >

                                            Map

                                    </Link>
                                </li>
                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/products'.length) === '/products' ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false} href="/products" as="/products">


                                            Products

                                    </Link>
                                </li>

                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/brands'.length) === '/brands' ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false} href="/brands" as="/brands">


                                            Brands

                                    </Link>
                                </li>
                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/strains'.length) === '/strains' ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false} href="/strains" as="/strains">


                                            Strains

                                    </Link>
                                </li>
                                <li className={`nav-item border-active ${window.location.pathname.substr(0, '/library'.length) === '/library' ? 'current' : ''}`}>
                                    <Link className="nav-link" prefetch={false} href="/library" as="/library">


                                            Library

                                    </Link>
                                </li>
                            </ul>


                            <div className='w-100 d-flex justify-content-end'>
                            {/* <div className='hide-desktop mt-2 mobile-location-t' style={{marginLeft:'auto'}}>
                                <div className="no-padding btn nav-link " style={{marginTop:'-10px'}}>
                                {
                                     !cookie.get(MY_LOCATION_PLACE_NAME) ? (
                                         <span style={{textDecoration:'underline'}} onClick={()=>this.openModal('location')}>
                                             <i className="search-btn bx bxs-map"></i>
                                             <span>Set location</span>
                                         </span>
                                     ) : (
                                         <>


                                             <span className='mr-0 pr-0 text-muted' style={{fontSize:'12px'}} onClick={()=>this.openModal('location')}>{this.locationDescription(cookie.get(MY_LOCATION_PLACE_NAME))}

                                             </span>

                                         </>
                                     )
                                 }
                                </div>
                            </div> */}
                                <div className='hide-mobile'>
                                    <button  aria-label='Cart button'  onClick={()=>this.openModal('shoppingCart')} type='button' className={`${this.state.searchOpened ? 'hide-mobile' : ''} btn cart-button pb-0 pt-0 text-dark`}>
                                        <i className="search-btn bx bxs-cart-alt" style={{fontSize:'20px', marginTop:'10px'}}></i>
                                        {
                                            this.props.products.length > 0 && <span className='badge badge-info badge-circle badge badge-info badge-circle badge-absolute'>
                                                {this.totalQuantity()}
                                            </span>
                                        }
                                    </button>
                                    {
                                        (Object.keys(user).length > 0 && user.constructor === Object) ? (
                                            <>

                                                <button type='button' onClick={()=>this.openModal('notification')} className={`btn pb-0 pt-0 text-dark cart-button ${this.state.searchOpened ? 'hide-mobile' : ''}`}>
                                                    <i className="search-btn bx bxs-bell" style={{fontSize:'20px', marginTop:'10px'}}></i>
                                                    { this.props.notifications.noReaded && this.props.notifications.noReaded > 0 &&
                                                        this.props.products.length > 0 && <span className='badge badge-info badge-circle badge badge-info badge-circle badge-absolute'>
                                                            {this.props.notifications.noReaded}
                                                        </span>
                                                    }
                                                </button>
                                                <button type='button' onClick={()=>this.openModal('user')} className={`btn pb-0 pt-0 text-dark ${this.state.searchOpened ? 'hide-mobile' : ''}`}>
                                                    <i className="search-btn bx bxs-user" style={{fontSize:'20px', marginTop:'10px'}}></i>
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button className='btn btn-sm text-dark hide-mobile mr-2' onClick={()=>this.openModal('signup')}>Sign up</button>
                                                <button className='btn btn-sm text-dark hide-mobile' onClick={()=>this.openModal('login')}>Login</button>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </nav>
                        <div className={`w-100  ${this.state.searchOpened ? 'pb-3 pl-3 pr-3 mobile-search-input-result' : '' } `} >
                        <MobileView>
                                <SearchInput
                                    // active={this.state.search}
                                    setSearchOpened={(value)=>this.setSearchOpened(value)}
                                    searchOpened={this.state.searchOpened}
                                    className={'mr-auto'}
                                    setSearchOpened={this.setSearchOpened}
                                    isMobile={true}
                                />
                            </MobileView>
                        </div>

                        {/* <MobileSearch hideOnTop={this.props.hideOnTop} setSearchOpened={this.setSearchOpened} searchOpened={this.state.searchOpened} /> */}
                    </div>
                </header>

                <MobileView>
                    {
                        this.state.searchOpened && <div className='header-overlay'></div>
                    }
                </MobileView>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        products: state.products,
        user: state.user,
        modals: state.modals,
        notifications: state.notifications
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateModalStatus: (modal) => {dispatch(updateModalStatus(modal))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

